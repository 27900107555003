import React from 'react'
import { BsDash } from "react-icons/bs";
import { FaPlus } from "react-icons/fa6";
import axiosInstance from '../../../utils/axiosInstance';
import GradientBtn from '../../../globalComponent/ui/GradientBtn';

const DocumentSelector = ({ distance, quantity, setQuantity, setSelectedCourier, setQuotId, setPageLoader }) => {

    const handleContinue = () => {

        const postData = {
            parcel_type: "DOCUMENT",
            distance: distance,
            document_quantity: quantity
        }

        setPageLoader(true)

        axiosInstance
        .post("/quotations/prices/", postData)
        .then(res => {
            console.log(res)
            const quotId = res.data.data.id
            setQuotId(quotId)
            setSelectedCourier(null)
            setPageLoader(false)
        })
        .catch(err => {
            console.log(err)
            setPageLoader(false)
        })
    }

  return (
    <div className='w-full flex md:shadow-2xl max-w-[50rem] md:px-2 md:mt-2 md:rounded-xl pb-2 flex-col items-center justify-center md:pt-4'>
        <p className='hidden md:block text-2xl text-[#525252] supermercado'>
            Number Of Documents
        </p>

        <div className='w-full max-w-[50rem] h-10 flex items-center justify-between gap-3 mt-3 mb-3'>
            <div className='h-full w-full rounded-lg bg-[#E6FFE8] flex items-center justify-start px-3'>
                {quantity} Document{quantity > 1 && "'s"}
            </div>

            <div className='h-full flex gap-2'>
                <button 
                    onClick={() => quantity > 1 && setQuantity(prev => prev - 1)}
                    className='h-full aspect-square rounded-lg bg-[#E6FFE8] flex items-center text-2xl justify-center '
                >
                    <BsDash />
                </button>
                <button 
                    onClick={() => quantity > 0 && setQuantity(prev => prev + 1)}
                    className='h-full aspect-square rounded-lg bg-opacity-50 flex items-center justify-center'
                    style={{ background: "#00C50A"}}
                >
                    <FaPlus />
                </button>
            </div>
        </div>

        <div className='w-full max-w-[50rem] mt-3'>
            <GradientBtn
                text={"continue"}
                onClickHandler={handleContinue}
            />
        </div>
    </div>
  )
}

export default DocumentSelector