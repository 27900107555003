import React from 'react'
import { GoChevronLeft } from "react-icons/go";
import { CiSearch } from "react-icons/ci";
import { Link, useNavigate } from 'react-router-dom';
import { RxAvatar } from "react-icons/rx";
import { useMobile } from '../context/MobileContext';
import tookiLogo from "../../assets/logo/tookiLogo.png"
import userProfileIcon from "../../assets/placeholder/profile-placeholder.png"

const MobileBackground = ({ title, children, searchIcon=true, profileIcon=true }) => {
    const { isMobile } = useMobile()
    const navigate = useNavigate()

    const handleBackBtn = () => {
        navigate(-1)
    }

  return (
    <div className='w-screen h-screen pt-10 pb-5 orange-gradient'>
        <div className='w-full h-[3rem] flex items-center justify-between gap-2 px-6'>
            <button
                onClick={handleBackBtn}
                className='border-none text-3xl md:text-8xl md:pt-10 text-black md:text-white mr-6 flex items-center justify-center w-10 aspect-square rounded-full'
            >
                <GoChevronLeft />
            </button>

            {isMobile?
            <p className='capitalize text-3xl font-regular supermercado'>
                {title}
            </p> :
            <div className='z-50 pt-10 '>
                <img
                    src={tookiLogo}
                    className='w-40 aspect-square'
                    alt=''
                />
            </div>
            }



            <div className='min-w-10 flex items-center gap-3'>
                {/* {searchIcon && 
                <button
                    className='border-none text-3xl flex items-center justify-center w-10 aspect-square rounded-full'
                >
                    <CiSearch />
                </button>} */}
                {profileIcon &&
                <Link
                    to={"/profile"}
                    className='w-14 lg:w-20 aspect-square md:mt-10'
                >
                    <img
                        src={userProfileIcon}
                        className='w-full max-h-full'
                        alt=''
                    />
                </Link>}
            </div>
        </div>
        <div className='w-full h-[calc(100%-3rem)] lg:px-20 lg:py-10'>
            {children}
        </div>
    </div>
  )
}

export default MobileBackground