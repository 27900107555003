import React from 'react'
import SignUp from '../components/signUp/SignUp'

const SignUpPage = () => {
  return (
    <div>
        <SignUp />
    </div>
  )
}

export default SignUpPage