import React, { useEffect, useState } from 'react';
import { FaStar } from "react-icons/fa";
import { IoPinOutline } from 'react-icons/io5';
import { useLocation } from 'react-router-dom';

import MobileBackground from '../../globalComponent/ui/MobileBackground';
import GoogleMapBg from './component/GoogleMapBg';
import GreenTick from '../../globalComponent/elements/GreenTick';

const YourOrder = () => {
    const location = useLocation();
    const locationData = location.state?.response.data;

    const [ map, setMap ] = useState(null);
    const [ orderData, setOrderData] = useState(JSON.parse(sessionStorage.getItem("order")))
    const [ distance, setDistance ] = useState(null);
    const [ directions, setDirections ] = useState(null);
    const [ pickUpLocation, setPickUpLocation ] = useState({ address: "", position: { lat: "", lng: "" } });
    const [ dropLocation, setDropLocation ] = useState({ address: "", position: { lat: "", lng: "" } });
    const [ currentLocation, setCurrentLocation ] = useState({ address: "", position: { lat: "", lng: "" } });

    // Load pickup and drop locations from session storage
    useEffect(() => {
        const pickup = JSON.parse(sessionStorage.getItem("pickup"));
        const drop = JSON.parse(sessionStorage.getItem("drop"));
        setPickUpLocation(pickup);
        setDropLocation(drop);
    }, []);

    useEffect(() => {
        const handleBeforeUnload = (event) => {
          event.preventDefault();
          event.returnValue = ''; // This is required for modern browsers
        };
    
        window.addEventListener('beforeunload', handleBeforeUnload);
    
        return () => {
          window.removeEventListener('beforeunload', handleBeforeUnload);
        };
      }, []);

    useEffect(() => {
        if (locationData) {
            setOrderData(locationData)
            sessionStorage.setItem("order", JSON.stringify(locationData))
        }
    }, [locationData])

    useEffect(() => {
        const handleBeforeUnload = (event) => {
          event.preventDefault();
          event.returnValue = ''; // This is required for modern browsers
        };
    
        window.addEventListener('beforeunload', handleBeforeUnload);
    
        return () => {
          window.removeEventListener('beforeunload', handleBeforeUnload);
        };
      }, []);    

    // Get current location
    useEffect(() => {
        navigator.geolocation.getCurrentPosition(
            (position) => {
                setCurrentLocation(prev => ({
                    ...prev,
                    position: {
                        lat: position.coords.latitude,
                        lng: position.coords.longitude
                    }
                }));
            },
            (error) => {
                if (error.code === error.PERMISSION_DENIED) {
                    alert("Location services are disabled. Please enable location services to move forward.");
                } else {
                    console.error(error);
                }
            }
        );
    }, []);

    return (
        <MobileBackground title="Your order">
            <div className='w-full relative h-full md:px-20 p-6'>
                <div className='w-full relative overflow-hidden h-full rounded-2xl md:rounded-[3rem] border-2 border-tooki-orange'>
                    <GoogleMapBg
                        pickUpLocation={pickUpLocation}
                        dropLocation={dropLocation}
                        currentLocation={currentLocation}
                        directions={directions}
                        distance={distance}
                        setDirections={setDirections}
                        setDistance={setDistance}
                        map={map}
                        setMap={setMap}
                    />
                </div>
                <OrderDetails 
                    pickUpLocation={pickUpLocation}
                    dropLocation={dropLocation}
                    orderData={orderData}
                />
            </div>
        </MobileBackground>
    );
};

// Component for order details overlay
const OrderDetails = ({ pickUpLocation, dropLocation, orderData }) => (
    <div className='absolute top-0 max-w-[40rem] left-0 md:left-1/2 md:-translate-x-1/2 translate-x-0 w-full h-full flex flex-col items-center justify-between z-10 px-9 pt-5'>
        <LocationInfoContainer 
            pickUpLocation={pickUpLocation}
            dropLocation={dropLocation}
        />
        <OrderInfoCard orderData={orderData} />
    </div>
);

// Component for location information
const LocationInfoContainer = ({ pickUpLocation, dropLocation }) => (
    <div className='w-full mt-3'>
        <LocationInfo icon={<IoPinOutline />} type="Pickup" address={pickUpLocation?.address} color="green" />
        <LocationInfo icon={<IoPinOutline />} type="Drop" address={dropLocation?.address} color="red" />
    </div>
);

// Component for order information card
const OrderInfoCard = ({ orderData }) => (
    <div className='w-full pb-5'>
        <div className='w-full mb-6 rounded-2xl shadow-green-lg h-[10rem] bg-white p-3'>
            <div className='w-full mb-5 flex items-center justify-between px-2'>
                <RideInfo distance={orderData?.distance_away} />
                <OtpInfo otp={orderData?.checkotp} />
            </div>
            <div className='w-full flex items-center justify-between px-2'>
                <DriverInfo name={orderData?.driver_name} rating={orderData?.overall_experience_rating} />
                <FareInfo fare={orderData?.driveroffer} />
            </div>
        </div>
    </div>
);

// Helper components
const LocationInfo = ({ icon, type, address, color }) => (
    <div className={`w-full px-3 shadow-md mb-2 h-1/2 flex items-center justify-between gap-3 bg-white rounded-md`}>
        <p className={`text-${color}-600 h-10 font-semibold flex items-center justify-center`}>
            {icon} {type}
        </p>
        <p className='text-nowrap text-left overflow-hidden'>{address}</p>
    </div>
);

const RideInfo = ({ distance }) => (
    <p className='h-10 text-sm flex items-center'>
        <GreenTick /> 
        Your Ride is 
        <span className='text-green-600 mx-1'>{distance}</span> Away
    </p>
);

const OtpInfo = ({ otp }) => (
    <div className='w-fit h-fit flex flex-col items-center justify-center'>
        <p className='font-light text-xs'>OTP</p>
        <div className='rounded-md px-2 py-1 bg-orange-500 bg-opacity-50'>
            <p className='text-orange-600 tracking-widest'>{otp}</p>
        </div>
    </div>
);

const DriverInfo = ({ name, rating }) => (
    <div className='w-full px-2 mt-3 flex items-center gap-5'>
        <div className='flex w-14 aspect-square items-center justify-center rounded-full overflow-hidden bg-gray-300'></div>
        <div className='text-left'>
            <p className='font-bold'>{name}</p>
            <p className='text-xs flex items-center gap-1'>
                <span className='text-orange-500'><FaStar /></span>
                <span className='text-gray-600'>{rating}</span>
            </p>
        </div>
    </div>
);

const FareInfo = ({ fare }) => (
    <div className='text-center'>
        <p className='text-xs text-gray-500'>Total Fare</p>
        <p className='text-orange-500 font-medium'>{fare} Rs</p>
    </div>
);

export default YourOrder;