import React, { useEffect, useState } from 'react'
import { FaStar } from 'react-icons/fa6';
import axiosInstance from '../../utils/axiosInstance';
import { useNavigate } from 'react-router-dom';
import { IoRefresh, IoClose } from "react-icons/io5";

// const driversData = [
//   {
//     driver_name: "John Doe",
//     vehicle: "Toyota Camry",
//     overall_experience_rating: 4.5,
//     driveroffer: 30,
//     distance_away: 12,
//     timeMins: 20,
//     timeHours: 0.33
//   },
//   {
//     driver_name: "Sarah Smith",
//     vehicle: "Honda Civic",
//     overall_experience_rating: 4.8,
//     driveroffer: 25,
//     distance_away: 10,
//     timeMins: 18,
//     timeHours: 0.3
//   },
//   {
//     driver_name: "Carlos Rivera",
//     vehicle: "Ford Fiesta",
//     overall_experience_rating: 4.3,
//     driveroffer: 28,
//     distance_away: 15,
//     timeMins: 25,
//     timeHours: 0.42
//   },
//   {
//     driver_name: "Aisha Khan",
//     vehicle: "Hyundai Elantra",
//     overall_experience_rating: 4.7,
//     driveroffer: 32,
//     distance_away: 13,
//     timeMins: 22,
//     timeHours: 0.37
//   },
//   {
//     driver_name: "David Lee",
//     vehicle: "Chevrolet Cruze",
//     overall_experience_rating: 4.6,
//     driveroffer: 27,
//     distance_away: 11,
//     timeMins: 17,
//     timeHours: 0.28
//   },
//   {
//     driver_name: "Maria Garcia",
//     vehicle: "Nissan Altima",
//     overall_experience_rating: 4.9,
//     driveroffer: 35,
//     distance_away: 16,
//     timeMins: 30,
//     timeHours: 0.5
//   }
// ];

const NoDriversAvailable = () => {
  return (
    <div className='w-full flex flex-col items-center justify-start p-8'>
      <p className='text-xl font-semibold animate-pulse'>
        Waiting for drivers
      </p>
    </div>
  )
}

const DriverCard = ({ name, fare, car, timeMins, rating, distance, userRide, rideId }) => {
  const navigate = useNavigate()

  const userId = sessionStorage.getItem("userId")

  const handleContinue = () => {
    const truncatedUserRide = userRide.length > 30 ? userRide.slice(0, 30) : userRide;
    const truncatedRideId = rideId.length > 30 ? rideId.slice(0, 30) : rideId;

    axiosInstance
      .post(`bargaining/RideGet/${userId}/driverassign/${truncatedRideId}/`)
      .then(res => {
        console.log(res);
        const response = res.data
        navigate("/your-order", { state: { response } });
      })
      .catch(err => {
        console.error(err);
      });
  }

  return (
    <div className='bg-[#FFE1CC] rounded-xl w-full h-fit p-3'>
      <div className='w-full flex items-center gap-3'>
          <div className='w-1/5 aspect-square rounded-full bg-gray-400'>
          </div>
          <div className="w-full">
            <div className='w-full flex items-center justify-between'>
              <p className='text-xl font-medium'>
                {name}
              </p>
              <p className='text-xl text-[#28C928] font-bold'>
                ₹ {fare}
              </p>
            </div>
            <div className='w-full flex items-center justify-between'>
              <p className='text-sm font-medium'>
                {car}
              </p>
              <p className='text-sm'>
                {timeMins} mins
              </p>
            </div>
            <div className='w-full flex items-center justify-between'>
              <p className='te font-medium flex items-center text-orange-500'>
                <FaStar />
                <span className='text-sm text-black ml-2'>{rating}</span>
              </p>
              <p className=''>
                {distance} away
              </p>
            </div>
          </div>
      </div>
      <div className='w-full mt-6 flex items-center justify-end'>
        <button
          onClick={handleContinue}
          className='px-10 py-2 bg-[#FF5C00] rounded-xl font-semibold text-lg'
        >
          Accept
        </button>
      </div>
    </div>
  )
}


const SelectDriver = ({ orderId, setShowDriverList }) => {
  const [ driversList, setDriverList ] = useState()

  const fetchDrivers = () => {
    if(orderId) {
      axiosInstance
      .get(`/bargaining/RideGet/${orderId}/`)
      .then(res => {
        const response = res.data;
        setDriverList(response)
        console.log(response)
      })
      .catch(err => {
        console.log(err)
      })
    }
  }

  useEffect(() => {
    const timer = setInterval(() => {
      fetchDrivers();
    }, 1000);
  
    return () => clearInterval(timer);
  }, [orderId]);   

  return (
    <div className='w-full min-h-screen relative backdrop-blur-sm md:pt-20 flex flex-col items-center justify-start'>
        <div className='w-full max-w-[40rem] h-20 bg-white md:bg-transparent flex items-end justify-end md:justify-between p-6 z-50'>
            {/* desktop */}
            <button
              onClick={() => setShowDriverList(false)}
              className='md:hidden text-opacity-60 text-black font-semibold '
            >
              Cancel
            </button>
            {/* mobile */}
            <button
              onClick={() => setShowDriverList(false)}
              className='text-black hidden text-3xl md:flex items-center justify-center p-2 rounded-full bg-[#FFE1CC] font-semibold '
            >
              <IoClose />
            </button>
        </div>
        {driversList?.length>0?
        <div className='w-full max-w-[40rem] p-4 bg-transparent flex flex-col items-center gap-4'>
            {driversList.map((item, id) => (
              <div key={id} className='w-full h-fit'>
                <DriverCard
                  userRide={item.userRide}
                  rideId={item.id}
                  name={item.driver_name}
                  fare={item.driveroffer}
                  car={item.vehicle}
                  timeMins={item.timeMins}
                  rating={item.overall_experience_rating}
                  distance={item.distance_away}
                />
              </div>
            ))}
        </div> : <NoDriversAvailable />}
    </div>
  )
}

export default SelectDriver