import React, { useEffect, useState } from 'react'
import axiosInstance from '../../../utils/axiosInstance';
import GradientBtn from '../../../globalComponent/ui/GradientBtn';
import { BiImageAdd } from "react-icons/bi";
import { FaCheck } from "react-icons/fa";

const ParcelSelector = ({ distance, courierSize, selectedType, setSelectedType, setSelectedCourier, setQuotId, setPageLoader, parcelImage, setParcelImage }) => {
    const [ dimensions, setDimensions ] = useState({
        weight: '',
        length: '',
        width: '',
        height: ''
      });

    useEffect(() => {
        if (!selectedType) {
            return
        }

        if (selectedType === courierSize[0].name) {
            setDimensions(prev => ({ ...prev, length: 20, width: 20, height: 20 }))
            return
        }

        if (selectedType === courierSize[1].name) {
            setDimensions(prev => ({ ...prev, length: 50, width: 50, height: 50 }))
            return
        }

        if (selectedType === courierSize[2].name) {
            setDimensions(prev => ({ ...prev, length: 100, width: 100, height: 100 }))
            return
        }
    }, [selectedType, courierSize])

    const handleSubmit = () => {

        if (!dimensions.weight || !dimensions.height || !dimensions.width) {
            alert("please enter all values")
            return
        }

        setPageLoader(true)

        const postData = {
            parcel_type: "PARCEL",
            distance: distance,
            weight: dimensions.weight,
            height: dimensions.height,
            width: dimensions.width
        }

        axiosInstance
        .post("/quotations/prices/", postData)
        .then(res => {
            const quotId = res.data.data.id
            setQuotId(quotId)
            setSelectedCourier(null)
            setPageLoader(false)
        })
        .catch(err => {
            console.log(err)
            setPageLoader(false)
        })
    }

    const handleImageUpload = (e) => {
        const file = e.target.files[0];
        if (file) {
            setParcelImage(file); // Storing the file object directly
        }
    };
    

  return (
    <div className='w-full max-w-[60rem] pt-6 md:pb-2 md:shadow-2xl md:px-3 rounded-2xl flex flex-col items-center justify-center'>

        <p className='text-center text-2xl supermercado pb-2 text-[#525252]'>
            Calculate Parcel
        </p>

        <div className='w-full flex items-center justify-between gap-3 h-12'>
            <input
                type='number'
                placeholder='Enter Weight'
                className='w-full h-full bg-[#E7FFE8] rounded-lg px-3 focus:outline-none'
                value={dimensions.weight}
                onChange={e => setDimensions(prev => ({ ...prev, weight: e.target.value }))}
            />
            <button className='h-full aspect-square bg-[#E7FFE8] text-lg rounded-lg'>
                KG
            </button>
        </div>

        <div className='w-full flex flex-col md:flex-row items-center justify-center md:gap-3'>
            <div className='w-full h-12 md:h-fit flex md:flex-col items-center justify-between gap-3 my-3'>
                <input
                    type='number'
                    placeholder='Length'
                    className='w-full h-full md:h-12 bg-[#e7ffe8] rounded-lg px-3 text-center focus:outline-none'
                    value={dimensions.length}
                    onChange={e => setDimensions(prev => ({ ...prev, length: e.target.value }))}
                />
                <input
                    type='number'
                    placeholder='Width'
                    className='w-full h-full md:h-12 bg-[#e7ffe8] rounded-lg px-3 text-center focus:outline-none'
                    value={dimensions.width}
                    onChange={e => setDimensions(prev => ({ ...prev, width: e.target.value }))}
                />
                <input
                    type='number'
                    placeholder='Height'
                    className='w-full h-full md:h-12 bg-[#e7ffe8] rounded-lg px-3 text-center focus:outline-none'
                    value={dimensions.height}
                    onChange={e => setDimensions(prev => ({ ...prev, height: e.target.value }))}
                />
                <div className='h-full relative w-fit md:w-full aspect-square md:aspect-auto md:h-10 rounded-lg bg-[#e7ffe8] flex items-center justify-center'>
                    <input
                        type='file'
                        accept='image/*'
                        className='h-full w-full absolute top-0 left-0 z-10 aspect-square opacity-0'
                        onChange={(e) => handleImageUpload(e)}
                    />
                    {parcelImage? 
                    <p className='text-3xl text-center  text-green-600'>
                        <FaCheck />
                    </p> : 
                    <p className='text-3xl tect-center text-black text-opacity-40'>
                        <BiImageAdd />
                    </p> }
                </div>
            </div>

            <p className='md:hidden text-xl supermercado font-medium text-left'>
                Common package size?
            </p>

            <p className='hidden md:block text-[#525252] supermercado text-2xl text-center'>
                Or
            </p>

            <div className='mt-3 w-full relative md:pt-10 flex items-center justify-center gap-3'>
                <p className='hidden md:block text-[#525252] absolute supermercado top-0 left-1/2 -translate-x-1/2 text-xl font-medium text-left'>
                    Common package size?
                </p>
                {courierSize.map((item, id) => (
                <div 
                    key={id} 
                    onClick={() => setSelectedType(item.name)} 
                    className='shadow-xl w-full relative aspect-square rounded-3xl flex flex-col items-center justify-center py-2'
                >
                    <div className='absolute top-2 right-2 w-3 aspect-square p-[1px] border-[1px] border-[#121212] overflow-hidden rounded-full'>
                        {selectedType === item.name && 
                        <div className='w-full h-full bg-[#121212] rounded-full'></div>}
                    </div>

                    <div className='w-1/2 mb-3 aspect-square rounded-full bg-[#E7FFE8]'></div>

                    <p className='text-xs text-[#00C50A]'>
                        {item.name}
                    </p>
                    <p className='text-[0.5rem] text-gray-500'>
                        {item.width} X {item.height}(cm)
                    </p>

                </div>))}
            </div>
        </div>

        <div className='w-full max-w-[30rem] mt-3'>
            <GradientBtn
                text={"continue"}
                onClickHandler={handleSubmit}
            />
        </div>
    </div>
  )
}

export default ParcelSelector