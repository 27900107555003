import React from 'react'
import DocumentSelector from './DocumentSelector'
import ParcelSelector from './ParcelSelector'
import LogisticsSelector from './LogisticsSelector'
import { IoClose } from 'react-icons/io5'

const courierSize = [
    { name: "Small", width: 20, height: 20 },
    { name: "Medium", width: 50, height: 50 },
    { name: "Large", width: 100, height: 100 },
];

const AllParcelSelector = ({
    selectedCourier,
    distance,
    docQuantity,
    setDocQuantity,
    setSelectedCourier,
    setQuotId,
    setPageLoader,
    selectedType,
    setSelectedType,
    parcelImage,
    setParcelImage
}) => {
  return (
    <div className='w-full relative md:mb-5'>
        {selectedCourier &&
        <button 
            onClick={() => setSelectedCourier(null)}
            className='w-10 hidden absolute top-0 right-0 aspect-square rounded-full bg-black bg-opacity-10 text-xl md:flex items-center justify-center'
        >
            <IoClose />
        </button>}

        {selectedCourier === "document" &&
        <div className='w-full flex items-center justify-center'>
            <DocumentSelector
                quantity={docQuantity}
                distance={distance}
                setQuantity={setDocQuantity}
                setSelectedCourier={setSelectedCourier}
                setQuotId={setQuotId}
                setPageLoader={setPageLoader}
            />
        </div>}

        {selectedCourier === "parcel" &&
        <div className='w-full flex items-center justify-center'>
            <ParcelSelector
                courierSize={courierSize}
                distance={distance}
                selectedType={selectedType}
                setSelectedType={setSelectedType}
                setSelectedCourier={setSelectedCourier}
                setQuotId={setQuotId}
                setPageLoader={setPageLoader}
                parcelImage={parcelImage}
                setParcelImage={setParcelImage}
            />
        </div>}

        {selectedCourier === "logistics" &&
        <div className='w-full flex items-center justify-center'>
            <LogisticsSelector
                courierSize={courierSize}
                distance={distance}
                selectedType={selectedType}
                setSelectedType={setSelectedType}
                setSelectedCourier={setSelectedCourier}
                setQuotId={setQuotId}
                setPageLoader={setPageLoader}
            />
        </div>}
    </div>
  )
}

export default AllParcelSelector