import React, { useState } from 'react'
import { useNavigate } from "react-router-dom"
import { useForm } from 'react-hook-form';
import { IoIosMail, IoIosLock, IoMdEye, IoMdEyeOff } from "react-icons/io";
import image1 from "../../assets/login/image-1.png"
import loginImage from "../../assets/login/login.png"
import "../../styles/component/login/Login.css"
import MobileBackground from '../../globalComponent/ui/MobileBackground';
import { ThreeDots } from 'react-loader-spinner';

const Loader = () => {
    return (
        <ThreeDots
            visible={true}
            height="80"
            width="80"
            color="#FFF"
            radius="9"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClass=""
        />
    )
}

const LoginMobile = ({ setOtpLogin, goToSignUp, onSubmit, loader, errorMessage, setForgotPass }) => {
    const { register, handleSubmit, formState: { errors } } = useForm()
    const [ showPassword, setShowPassword] = useState(false)

  return (
    <MobileBackground title={"Sign In"} profileIcon={false} searchIcon={false}>
        <div className='login-main-div relative'>
            {errorMessage &&
            <p className='pb-3 text-red-500 font-light glow-red absolute top-3 left-1/2 -translate-x-1/2'>
                {errorMessage}
            </p>}

            <div className='top-image-container'>
                <img src={loginImage} className='login-hero-image' alt='hero' />
            </div>

            <div className='bottom-form-container'>
                {/* <p className='main-title'>
                    Sign In
                </p> */}
                <p className='welcome-text'>
                    Welcome!
                </p>
                {/* <p className='form-title'>
                    Sign in to continue
                </p> */}

                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="form-group">
                        <div className='icon-wrap'>     
                            <IoIosMail />
                        </div>
                        <input
                            type="email"
                            placeholder='Email'
                            disabled={loader && true}
                            {...register("email", { required: true })}
                            className={errors.email? 'form-control input-error' : "form-control disabled:text-gray-400"}
                        />
                    </div>

                    <div className="form-group">
                        <div className='icon-wrap'>
                            <IoIosLock />
                        </div>
                        <input
                            type={showPassword ? 'text' : 'password'}
                            placeholder='Password'
                            disabled={loader && true}
                            {...register("password", { required: true })}
                            className={errors.email? 'form-control input-error' : "form-control disabled:text-gray-400"}
                        />
                        <button
                            type="button"
                            className="show-hide-btn"
                            onClick={() => setShowPassword(!showPassword)}
                        >
                            {showPassword ? <IoMdEyeOff /> : <IoMdEye /> }
                        </button>
                    </div>

                    <div className='w-full flex items-center justify-between mb-2'>
                        <div className='checkbox-div'>
                            <p onClick={() => setOtpLogin(true)} className='underline text-orange-600 text-xs font-medium'>
                                sign in with otp
                            </p>
                        </div>
                        <button 
                            onClick={() => setForgotPass(true)}
                            className='text-sm text-gray-600 text-opacity-70'
                        >
                            Forgot Password?
                        </button>
                    </div>

                    <button disabled={loader} type="submit" className="main-login-btn">
                        {!loader? "Login" : <Loader />}
                    </button>

                    <button onClick={goToSignUp} className='create-new-btn'>
                        Create a new account
                    </button>
                </form>
            </div>
        </div>
    </MobileBackground>
  )
}

export default LoginMobile