import React, { useEffect, useState } from 'react'
import Login from '../components/login/Login'
import LoginMobile from '../components/login/LoginMobile'
import { useMobile } from '../globalComponent/context/MobileContext'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import axiosInstance from '../utils/axiosInstance'
import { useUserContext } from '../globalComponent/context/UserContext'
import ForgotPassword from '../components/login/ForgotPassword'
import LoginOtp from '../components/login/LoginOtp'

const LoginPage = () => {
  const { isMobile } = useMobile()
  const { setUserId, setAlert } = useUserContext()
  const navigate = useNavigate()
  const [ loader, setLoader ] = useState(false)
  const [ errorMessage, setErrorMessage ] = useState()
  const [ forgotPass, setForgotPass ] = useState(false)
  const [ otpLogin, setOtpLogin ] = useState(true)

    useEffect(() => {
        localStorage.clear()
        sessionStorage.clear()
    }, [])

    useEffect(() => {
        if (errorMessage) {
          const timer = setTimeout(() => {
            setErrorMessage(null);
          }, 3000); // Set to 3 seconds
      
          // Clear the timeout if the component unmounts or errorMessage changes
          return () => clearTimeout(timer);
        }
      }, [errorMessage]);

  const onSubmit = (data) => {
    setLoader(true)
      const loginData = {
          email: data.email,
          password: data.password,
          login_type: "consumer"
      }
      axiosInstance
          .post("/login", loginData)
          .then(res => {
              const response = res.data.data
              const userName = response.user.first_name + " " + response.user.last_name
              console.log(res.data.data)
              localStorage.setItem("access", res.data.data.access)
              localStorage.setItem("refresh", res.data.data.refresh)
              localStorage.setItem("name", userName)
              console.log(response)
              setUserId(response.user.id)
              navigate("/send-courier")
          })
          .catch(err => {
              // console.log(err)
              // (err.response.status === 400) && alert("Invalid user id or password")
              setAlert(err.response.data.message)
          })
          .finally(() => {
            setLoader(false)
          })
  };

  const goToSignUp = () => {
      navigate("/signup")
  }
  return (
    <>

      {forgotPass && 
      <ForgotPassword
          setForgotPass={setForgotPass}
      />}

      {otpLogin && <LoginOtp setOtpLogin={setOtpLogin} />}

      {!otpLogin && (
        isMobile ? 
          <LoginMobile
            goToSignUp={goToSignUp}
            onSubmit={onSubmit}
            setUserId={setUserId}
            errorMessage={errorMessage}
            loader={loader}
            setForgotPass={setForgotPass}
            setOtpLogin={setOtpLogin}
          /> 
        : 
          <Login 
            goToSignUp={goToSignUp}
            onSubmit={onSubmit}
            setUserId={setUserId}
            errorMessage={errorMessage}
            loader={loader}
            setForgotPass={setForgotPass}
            setOtpLogin={setOtpLogin}
          />
      )} 
    </>
  )
}

export default LoginPage