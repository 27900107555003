import React from 'react'
import Background from '../Tutorial/component/Background'

const Review = () => {
  return (
    <Background>
            <div className='w-full h-full '>

            </div>
    </Background>
  )
}

export default Review