import { useNavigate } from 'react-router-dom';
import MobileBackground from '../../globalComponent/ui/MobileBackground';
import { GoChevronRight } from "react-icons/go";
import profileImage from "../../assets/tutorial/profile.png"
import privacyPolicy from "../../assets/docs/privacy-policy-tooki.pdf"
import termsAndConditions from "../../assets/docs/terms-and-condition.pdf"

const  PinkButton = ({ text, onClickHandler }) => {
  return (
    <button
      // disabled={onClickHandler? true : false}
      onClick={onClickHandler && onClickHandler}
      className='h-16 w-full bg-[#FFEFEA] flex items-center justify-between ga-2 px-8'
    >
      <p className='capitalize font-medium text-lg'>
        {text}
      </p>
      <p className='text-2xl'>
        <GoChevronRight />
      </p>
    </button>
  )
}

const UserProfile = () => {
    const navigate = useNavigate()
    const userName = localStorage.getItem("name")
    // const { isMobile } = useMobile()\

    const handleLogOut = () => {
      navigate("/login")
    }

    function handleTandC() {
      window.open(termsAndConditions, "_blank");
    }

    function handlePrivacyPolicy() {
      window.open(privacyPolicy, "_blank");
    }

    const goToOrderHistory = () => {
      navigate("/order-history")
    }

    const goToBookAgain = () => {
      navigate("/book")
    }

  return (
    <MobileBackground title={"My Profile"}>
      <div className='w-full h-full md:bg-white rounded-[3rem] md:px-2 pt-8 md:pb-2 flex flex-col md:flex-row items-center justify-start gap-0 md:gap-10'>
        <div 
          style={{ background: "linear-gradient(89.79deg, #FFFFFF -2.03%, rgba(230, 255, 232, 0.75) 50.93%, #FFFFFF 99.82%)"}}
          className='w-full p-8 flex flex-col justify-between text-left md:shadow-2xl md:rounded-[3rem] md:h-full'
        >
          <div className='w-full px-32 hidden md:block'>
            <img
              src={profileImage}
              className='w-full'
              alt=''
            />
          </div>
          <div 
            style={{ background: "linear-gradient(89.79deg, #FFFFFF -2.03%, rgba(230, 255, 232, 0.75) 50.93%, #FFFFFF 99.82%)"}}
            className='w-full'
          >
            <p className='text-2xl capitalize font-medium'>
              {userName}
            </p>
            <button
              disabled
              className='w-full mt-20 bg-[#00C50A] rounded-lg p-3 text-white text-xl font-medium'
            >
              Add GST Details
            </button>
          </div>
        </div>

        <div className='w-full text-left pt-10 md:pt-0'>
            <PinkButton 
              text={"saved addresses"} 
            />
            <PinkButton 
              text={"Previous Orders"} 
              onClickHandler={goToOrderHistory}
            />
            <PinkButton 
              text={"Book With Number"} 
              onClickHandler={goToBookAgain}
            />

            <p className='px-4 py-2 text-sm'>
              Platform Policies
            </p>
            <PinkButton 
              text={"Privacy Policy"} 
              onClickHandler={handlePrivacyPolicy}
            />
            <PinkButton 
              text={"terms & conditions"} 
              onClickHandler={handleTandC}
            />

            <p className='px-4 py-2 text-sm'>
              Settings
            </p>
            <PinkButton 
              text={"account settings"} 
            />
            <PinkButton 
              text={"log out"} 
              onClickHandler={handleLogOut}
            />
        </div>
      </div>
    </MobileBackground>
  )
}

export default UserProfile