import React from 'react'
import greenTick from "../../assets/icons/greenTick.png"

const GreenTick = () => {
  return (
    <img
        src={greenTick}
        className='h-full w-auto'
        alt='tick'
    />
  )
}

export default GreenTick