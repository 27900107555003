import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation } from "react-router-dom"
import { useForm } from 'react-hook-form';
import { IoMdEye, IoMdEyeOff } from "react-icons/io";
import Background from '../Tutorial/component/Background'
import cameraInputImage from "../../assets/login/image-input.png"
import Navigator from '../../globalComponent/navigator/Navigator';
import { useMobile } from '../../globalComponent/context/MobileContext';
import axiosInstance from '../../utils/axiosInstance';
import Camera from '../../globalComponent/camera/Camera';
import "../../styles/component/signUp/SignUp.css"
import majorCities from '../../data/majorCities';
import { ThreeDots } from 'react-loader-spinner';
import { useUserContext } from '../../globalComponent/context/UserContext';

const SignUp = () => {
    const { isMobile } = useMobile()
    const location = useLocation()
    const navigate = useNavigate()
    const phoneNumber = location.state?.phoneNumber;
    
    const { setAlert } = useUserContext()
    const { register, handleSubmit, formState: { errors }, setValue } = useForm()
    const [ showPassword, setShowPassword] = useState(false)
    const [ openCamera, setOpenCamera ] = useState(false)
    const [ photos, setPhotos ] = useState()
    const [ errorMessage, setErrorMessage ] = useState([])
    const [ loader, setLoader ] = useState(false)

    // useEffect(() => {
    //     console.log(photos)
    // }, [photos])

    useEffect(() => {
        if (phoneNumber) {
            setValue("phNumber", phoneNumber); // Set the value of the phone number input
        }
    }, [phoneNumber, setValue]);

    useEffect(() => {
        if (errorMessage) {
          const timer = setTimeout(() => {
            setErrorMessage(null);
          }, 6000); // Set to 6 seconds
      
          return () => clearTimeout(timer);
        }
      }, [errorMessage]);

    const onSubmit = (data) => {
        if (data.password !== data.confirmPassword) {
            setAlert("Passwords don't match!")
            return
        }

        const name = data.fullName.split(" ")

        if (!name[0] || !name[1]) {
            setAlert("Please enter full name")
            return
        }

        const loginData = {
            first_name: name[0],
            last_name: name[name.length-1],
            phone_number: data.phNumber,
            email: data.email,
            password: data.password,
            user_type: "consumer",
            // ...(photos?.image && { profile_photo: photos.blob })
        }

        const formData = new FormData();

        Object.keys(loginData).forEach(key => {
            formData.append(key, loginData[key]);
        });
        
        setLoader(true)

        axiosInstance
            .post("/register", formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then(res => {
                setAlert("welcome to tooki, Please login")
                navigate("/login", { state: { phoneNumber: phoneNumber }})
            })
            .catch(err => {
                const errors = err.response.data.data;
                const allMessages = [];
                
                for (let field in errors) {
                    if (errors.hasOwnProperty(field)) {
                        allMessages.push(`${field}: ${errors[field].join(', ')}`);
                    }
                }

                setErrorMessage(allMessages); // Set the array of error messages
            })
            .finally(() => {
                setLoader(false)
            })
    };

  return (
    <Background noBg={isMobile && true}>
        <div className='sign-up-main-div'>

            {openCamera &&
            <div 
                onClick={() => setOpenCamera(false)}
                className='w-screen h-screen fixed top-0 left-0 z-[999] bg-black bg-opacity-40 flex items-center justify-center px-4'>
                <Camera
                    setPhotos={setPhotos}
                    setIsCameraVisible={setOpenCamera}
                />
            </div>}

           {isMobile &&
            <div className='navigator-main-container'>
                <Navigator pageTitle={"Sign Up"}/>
            </div>}

            {!isMobile &&
            <div className='image-container p-10'>
                <div className='w-full aspect-square rounded-full overflow-hidden flex items-center justify-center'>
                    {photos? 
                    <img 
                        src={photos.image}
                        className='w-full h-full object-cover' 
                        onClick={() => setOpenCamera(true)}
                        alt='tutorial' 
                    /> : 
                    <img 
                        src={cameraInputImage}
                        className='hero-image' 
                        onClick={() => setOpenCamera(true)}
                        alt='tutorial' 
                    />}
                </div>
            </div>}

            <div className='divider'></div>

            <div className='main-form-container relative'>
                <p className='main-title supermercado'>
                    Sign Up
                </p>

                {isMobile &&
                <div className='image-container-mobile w-full aspect-square rounded-full overflow-hidden flex items-center justify-center'>
                    {photos? 
                    <img 
                        src={photos.image}
                        className='w-full h-full object-cover' 
                        onClick={() => setOpenCamera(true)}
                        alt='tutorial' 
                    /> : 
                    <img 
                        src={cameraInputImage}
                        className='hero-image' 
                        onClick={() => setOpenCamera(true)}
                        alt='tutorial' 
                    />}
                </div>}

                <form className='relative pt-8' onSubmit={handleSubmit(onSubmit)}>
                    {/* {errorMessage?.length > 0 && (
                        <ul className='p-3 max-w-[90%] px-2 fixed text-left bg-orange-500 rounded-2xl top-5 left-1/2 -translate-x-1/2 z-50 text-white'>
                            {errorMessage?.map((msg, idx) => (
                                <li className='w-full text-sm font-medium text-nowrap capitalize' key={idx}>{msg}</li>
                            ))}
                        </ul>
                    )} */}
                    <div className="w-full bg-[#E6FFE8] rounded-lg h-10 mb-4 overflow-hidden">
                        <input
                            type="text"
                            placeholder="Full Name"
                            {...register("fullName", { required: true })}
                            className={`w-full h-full bg-transparent rounded-lg text-lg text-black focus:outline-none px-5
                            ${errors.fullName ? 'border-red-600 border-2' : ""}`}
                        />
                    </div>
                    <div className="w-full bg-[#E6FFE8] rounded-lg h-10 mb-4 overflow-hidden">
                        <input
                            type="number"
                            placeholder='Phone Number'
                            {...register("phNumber", {
                                required: "Phone number is required",
                                pattern: {
                                    value: /^[0-9]{10}$/,
                                    message: "Invalid phone number"
                                }
                            })}
                            className={`w-full h-full bg-transparent rounded-lg text-lg text-black focus:outline-none px-5
                            ${errors.phNumber ? 'border-red-600 border-2' : ""}`}
                        />
                        {/* {errors.phNumber && <p className="error-message text-nowrap text-red-600 text-sm absolute -top-5 left-1/2 -translate-x-1/2">{errors.phNumber.message}</p>} */}
                    </div>

                    <div className="w-full bg-[#E6FFE8] rounded-lg h-10 mb-4 overflow-hidden">
                        <select
                            {...register("area", { required: true })}
                            className={`w-full h-full bg-transparent rounded-lg text-lg text-black focus:outline-none px-5
                            ${errors.area ? 'border-red-600 border-2' : ""}`}
                        >
                            <option value="">Select Area</option>
                            {majorCities.map(city => (
                                <option key={city} value={city}>{city}</option>
                            ))}
                        </select>
                        {/* {errors.area && <span className="error-message">This field is required</span>} */}
                    </div>

                    <div className="w-full bg-[#E6FFE8] rounded-lg h-10 mb-4 overflow-hidden">
                        <input
                            type="email"
                            placeholder='Email'
                            {...register("email", { required: "Email is required" })}
                            className={`w-full h-full bg-transparent rounded-lg text-lg text-black focus:outline-none px-5
                            ${errors.email ? 'border-red-600 border-2' : ""}`}
                        />
                        {/* {errors.email && <p className="error-message text-nowrap text-red-600 text-sm">{errors.email.message}</p>} */}
                    </div>

                    <div className="w-full bg-[#E6FFE8] relative rounded-lg h-10 mb-4 overflow-hidden">
                        <input
                            type={showPassword ? 'text' : 'password'}
                            placeholder='Password'
                            {...register("password", {
                                required: "Password is required",
                                pattern: {
                                    value: /^(?=.*[a-z])(?=.*\d).{8,}$/,
                                    message: "Min 8 characters and one number"
                                }
                            })}
                            // (?=.*[A-Z])
                            className={`w-full h-full bg-transparent rounded-lg text-lg text-black focus:outline-none px-5
                            ${errors.password ? 'border-red-600 border-2' : ""}`}
                        />
                        <button
                            type="button"
                            className="absolute right-4 top-4 text-green-600 text-2xl"
                            onClick={() => setShowPassword(!showPassword)}
                        >
                            {!showPassword ? <IoMdEyeOff /> : <IoMdEye /> }
                        </button>
                        {/* {errors.password && <p className="error-message text-nowrap text-red-600 text-sm absolute -top-5 left-1/2 -translate-x-1/2">{errors.password.message}</p>} */}
                    </div>

                    <div className="w-full bg-[#E6FFE8] rounded-lg h-10 mb-4 overflow-hidden">
                        <input
                            type={showPassword ? 'text' : 'password'}
                            placeholder='Confirm Password'
                            {...register("confirmPassword", { required: "Please confirm your password" })}
                            className={`w-full h-full bg-transparent rounded-lg text-lg text-black focus:outline-none px-5
                            ${errors.confirmPassword ? 'border-red-600 border-2' : ""}`}
                        />
                        {/* {errors.confirmPassword && <p className="error-message text-nowrap text-red-600 text-sm absolute -top-5 left-1/2 -translate-x-1/2">{errors.confirmPassword.message}</p>} */}
                    </div>

                    <button type="submit" className="main-sign-up-btn">
                        {!loader? "Sign Up" : <ThreeDots color='#ffffff' />}
                    </button>
                </form>

                <p className='login-text'>
                    Already have an account? <span onClick={() => navigate("/login")}>Sign In</span>
                </p>
            </div>
        </div>
    </Background>
  )
}

export default SignUp