import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import LoadingScreen from './globalComponent/Loader/LoadingScreen';
import TutorialPage from './pages/TutorialPage';
import LoginPage from './pages/LoginPage';
import SignUpPage from './pages/SignUpPage';
import SetupPage from './pages/SetupPage';
import UserProfile from './components/UserProfile/UserProfile';
import ReviewPage from './pages/ReviewPage';
import PreviousOrders from './components/previousOrders/PreviousOrders';
import SubTotal from './components/courier/SubTotal';
import YourOrder from './components/courier/YourOrder';
import BookAgainPage from './pages/BookAgainPage';
import { MobileProvider } from './globalComponent/context/MobileContext';
import { UserProvider } from './globalComponent/context/UserContext';
import { LoaderProvider } from './components/courier/component/LoaderContext';
import Alert from './globalComponent/ui/Alert';
import './App.css';

function App() {
  const [ isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const startTime = Date.now();

    // Simulate page content loading (replace with actual loading logic)
    setTimeout(() => {
      const endTime = Date.now();
      const elapsedTime = endTime - startTime;

      // Adjust the minimum loading time if the page content takes longer than 500ms
      const minLoadingTime = Math.max(500, elapsedTime);

      const timer = setTimeout(() => {
        setIsLoading(false);
      }, minLoadingTime);

      return () => clearTimeout(timer);
    }, 500); // Simulated page content loading time
  }, []);

  // loading screen
  if (isLoading) {
    return <LoadingScreen />
  }

  return (
    <div className="App">
      <LoaderProvider>
        <MobileProvider>
          <UserProvider>
            <Router>
              <Alert />
              <Routes>
                <Route path='/' element={<Navigate to="/tutorial" />} />
                <Route path='/tutorial' element={<TutorialPage />} />
                <Route path='/login' element={<LoginPage />} />
                <Route path='/signup' element={<SignUpPage />} />
                <Route path='/setup' element={<SetupPage />} />
                <Route path='/profile' element={<UserProfile />} />
                <Route path='/send-courier' element={<SubTotal />} />
                <Route path='/your-order' element={<YourOrder />} />
                <Route path='/book/*' element={<BookAgainPage />} />
                <Route path='/review' element={<ReviewPage />} />
                <Route path='/order-history' element={<PreviousOrders />} />
              </Routes>
            </Router>
          </UserProvider>
        </MobileProvider>
      </LoaderProvider>
    </div>
  );
}

export default App;
