import React, { useEffect, useState } from 'react'
import axiosInstance from '../../../utils/axiosInstance';
import bikeIcon from "../../../assets/icons/bike.png"
import truckIcon from "../../../assets/icons/truck.png"
import autoIcon from "../../../assets/icons/3Wheeler.png"
import pickupTruckIcon from "../../../assets/icons/pickupTruck.png"
import GradientBtn from '../../../globalComponent/ui/GradientBtn';

const LogisticsButton = ({ image, label, weight }) => {
    return (
        <button
            className='w-full h-20 rounded-xl border-[1.5px] border-[#FA4822] flex items-center justify-start gap-4 p-3'
        >
            <div className='h-full aspect-square rounded-full flex items-center justify-center p-1 bg-[#FFA788] bg-opacity-20'>
                <img
                    src={image}
                    className='object-contain'
                    alt=''
                />
            </div>
            <div className='h-full flex flex-col items-start justify-center'>
                <p className='text-[#FA4821] font-medium text-xl'>
                    {label}
                </p>
                <p className='text-xs font-light'>
                    {weight} KG
                </p>
            </div>
        </button>
    )
}

const LogisticsSelector = ({ distance, courierSize, selectedType, setSelectedType, setSelectedCourier, setQuotId, setPageLoader }) => {
    const [dimensions, setDimensions] = useState({
        weight: '',
        length: '',
        width: '',
        height: ''
      });

    useEffect(() => {
        if (!selectedType) {
            return
        }

        if (selectedType === courierSize[0].name) {
            setDimensions(prev => ({ ...prev, length: 20, width: 20, height: 20 }))
            return
        }

        if (selectedType === courierSize[1].name) {
            setDimensions(prev => ({ ...prev, length: 50, width: 50, height: 50 }))
            return
        }

        if (selectedType === courierSize[2].name) {
            setDimensions(prev => ({ ...prev, length: 100, width: 100, height: 100 }))
            return
        }
    }, [selectedType, courierSize])

    const handleSubmit = () => {

        // if (!dimensions.weight || !dimensions.height || !dimensions.width) {
        //     alert("please enter all values")
        //     return
        // }

        // setPageLoader(true)

        // const postData = {
        //     parcel_type: "PARCEL",
        //     distance: distance,
        //     weight: dimensions.weight,
        //     height: dimensions.height,
        //     width: dimensions.width
        // }

        // axiosInstance
        // .post("/quotations/prices/", postData)
        // .then(res => {
        //     const quotId = res.data.data.id
        //     setQuotId(quotId)
        //     setSelectedCourier(null)
        //     setPageLoader(false)
        // })
        // .catch(err => {
        //     console.log(err)
        //     setPageLoader(false)
        // })
    }

  return (
    <div className='w-full max-w-[60rem] pt-6 pb-6 md:pb-2 rounded-2xl shadow-2xl px-3 flex flex-col items-center justify-center md:px-10'>

        <p className='text-center text-2xl supermercado pb-2 text-[#525252]'>
            Choose Logistics
        </p>

        <div className='w-full flex flex-col md:flex-row items-start justify-start md:gap-10 gap-4'>
            <div className='md:w-1/2 w-full flex flex-col items-start justify-start gap-4'>
                <LogisticsButton
                    image={bikeIcon}
                    label={"2 wheeler"}
                    weight={20}
                />
                <LogisticsButton
                    image={pickupTruckIcon}
                    label={"4ft Pickup Truck"}
                    weight={40}
                />
            </div>
            <div className='md:w-1/2 w-full flex flex-col items-start justify-start gap-4'>
                <LogisticsButton
                    image={truckIcon}
                    label={"8ft Pickup Truck"}
                    weight={80}
                />
                <LogisticsButton
                    image={autoIcon}
                    label={"3 Wheeler"}
                    weight={30}
                />
            </div>
        </div>

        <div className='w-full max-w-[30rem] mt-3'>
            <GradientBtn
                text={"continue"}
                onClickHandler={handleSubmit}
            />
        </div>
    </div>
  )
}

export default LogisticsSelector