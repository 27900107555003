import React, { useEffect, useState } from 'react';
import { ThreeCircles } from 'react-loader-spinner';
import { APIProvider } from '@vis.gl/react-google-maps';
import { useNavigate } from 'react-router-dom';

import axiosInstance from '../../utils/axiosInstance';
import MobileBackground from '../../globalComponent/ui/MobileBackground';
import LocationSelections from '../../globalComponent/ui/LocationSelections';
import GradientBtn from '../../globalComponent/ui/GradientBtn';
import SelectDriver from './SelectDriver';
import OfferYourFarePopUp from './component/OfferYourFarePopUp';
import AllParcelSelector from './component/AllParcelSelector';
import GoogleMapBg from './component/GoogleMapBg';
import { useLoader } from './component/LoaderContext';
import { useMobile } from '../../globalComponent/context/MobileContext';
import { useUserContext } from '../../globalComponent/context/UserContext';

import documentIcon from "../../assets/courierPage/documentIcon.png";
import parcelIcon from "../../assets/courierPage/parcel.png";
import logisticsIcon from "../../assets/courierPage/logisticsIcon.png";

import "../../styles/component/courier/SubTotal.css";

// Define courier types and sizes
const courierTypes = [
    { icon: documentIcon, type: "document" },
    { icon: parcelIcon, type: "parcel" },
    { icon: logisticsIcon, type: "logistics" },
];

const SubTotal = () => {
    const { isMobile } = useMobile();
    const { setAlert } = useUserContext();
    const { isLoaded } = useLoader();
    const navigate = useNavigate()
    const userId = sessionStorage.getItem("userId");
    const orderData = JSON.parse(sessionStorage.getItem("order"))

    // State variables
    const [ quotId, setQuotId] = useState(sessionStorage.getItem("quotId"));
    const [ pageLoader, setPageLoader] = useState(false);
    const [ quotData, setQoutData] = useState();
    const [ showDriverList, setShowDriverList] = useState(false);
    const [ showOfferPopUp, setShowOfferPopUp] = useState(false);
    const [ offerPrice, setOfferPrice] = useState(Number);
    const [ selectedCourier, setSelectedCourier] = useState();
    const [ docQuantity, setDocQuantity] = useState(1);
    const [ selectedType, setSelectedType] = useState();
    const [ orderId, setOrderId] = useState();
    const [ map, setMap] = useState(null);
    const [ distance, setDistance] = useState(null);
    const [ directions, setDirections] = useState(null);
    const [ parcelImage, setParcelImage] = useState();
    const [ pickUpLocation, setPickUpLocation] = useState({ address: "", position: { lat: "", lng: "" } });
    const [ dropLocation, setDropLocation] = useState({ address: "", position: { lat: "", lng: "" } });
    const [ currentLocation, setCurrentLocation] = useState({ address: "", position: { lat: "", lng: "" } });

    // Set pickup location to current location if not provided
    useEffect(() => {
        if (pickUpLocation.address === "") {
            setPickUpLocation(currentLocation);
        }
    }, [currentLocation]);

    useEffect(() => {
        if (orderData) {
            const response = {
                data: orderData
            }

            console.log(response)

            navigate("/your-order", { state: { response } });
        }
    }, [orderData])

    // Store pickup and drop locations in session storage
    useEffect(() => {
        sessionStorage.setItem("pickup", JSON.stringify(pickUpLocation));
        sessionStorage.setItem("drop", JSON.stringify(dropLocation));
    }, [pickUpLocation, dropLocation]);

    // Fetch quotation data
    useEffect(() => {
        if (quotId) {
            setPageLoader(true);
            axiosInstance.get(`/quotations/prices/${quotId}/`)
                .then(res => {
                    setQoutData(res.data.data);
                    setPageLoader(false);
                })
                .catch(err => {
                    console.error(err);
                    setPageLoader(false);
                });
        }
    }, [quotId]);

    // Helper function to capitalize first letter of each word
    const capitalize = (str) => {
        return str.toLowerCase().split(' ')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    };

    // Determine the fare based on offered price or estimated amount
    const handleFare = () => {
        return Math.round(offerPrice && offerPrice !== 0 ? offerPrice : quotData.estimated_amount);
    };

    // Submit the order
    const submitOrder = () => {
        setPageLoader(true);
        const formData = new FormData();
        const postData = {
            type: capitalize(quotData.parcel_type),
            pickup_location: pickUpLocation.address,
            drop_location: dropLocation.address,
            offer_fare: handleFare(),
            parcel_images: parcelImage
        };

        Object.keys(postData).forEach(key => {
            if (key === "parcel_images" && postData[key]) {
                formData.append("parcel_image", postData[key]);
            } else {
                formData.append(key, postData[key]);
            }
        });

        axiosInstance.post(`/bargaining/${userId}/`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' }
        })
        .then(res => {
            setOrderId(res.data.id);
            setShowDriverList(prev => !prev);
        })
        .catch(err => {
            console.error(err);
            setAlert(err.response.data.error);
        })
        .finally(() => {
            setPageLoader(false);
        });
    };

    // Handle courier selection
    const handleCourierSelect = (type) => {
        if (pickUpLocation.address === "" || dropLocation.address === "") {
            alert("Please enter pickup and drop locations");
            return;
        }
        if (!quotId) {
            setSelectedCourier(type);
        }
    };

    return (
        <APIProvider apiKey={process.env.REACT_APP_GOOGLE_API_KEY}>
            <MobileBackground title="Tooki">
                <div className='w-full h-full p-4 md:px-20 relative'>
                    
                    {pageLoader && 
                    <div className='w-screen z-50 pointer-events-none fixed top-0 left-0 h-screen flex items-center justify-center'>
                        <ThreeCircles color="#F95426" />
                    </div>}

                    {showOfferPopUp && (
                    <OfferYourFarePopUp
                        userId={userId}
                        offerPrice={offerPrice}
                        setOfferPrice={setOfferPrice}
                        setShowOfferPopUp={setShowOfferPopUp}
                        pickUpLocation={pickUpLocation}
                        dropLocation={dropLocation}
                        setPageLoader={setPageLoader}
                    />)}

                    {showDriverList && (
                    <div className='w-screen h-screen overflow-scroll backdrop-blur-md fixed z-50 left-0 top-0'>
                        <SelectDriver 
                            setShowDriverList={setShowDriverList}
                            orderId={userId}
                        />
                    </div>)}

                    <div className='w-full h-full rounded-2xl md:rounded-[3rem] border-2 border-tooki-orange shadow-2xl overflow-hidden relative'>
                        <GoogleMapBg
                            isLoaded={isLoaded}
                            pickUpLocation={pickUpLocation}
                            dropLocation={dropLocation}
                            currentLocation={currentLocation}
                            directions={directions}
                            setDirections={setDirections}
                            setDistance={setDistance}
                            map={map}
                            setMap={setMap}
                        />

                        <div className='absolute top-0 left-0 w-full p-4 md:left-1/2 md:-translate-x-1/2 translate-x-0 max-w-[40rem]'>
                            <LocationSelections
                                isLoaded={isLoaded}
                                pickUpLocation={pickUpLocation}
                                setPickUpLocation={setPickUpLocation}
                                dropLocation={dropLocation}
                                setDropLocation={setDropLocation}
                                currentLocation={currentLocation}
                                setCurrentLocation={setCurrentLocation}
                            />
                        </div>

                        <div className='absolute bottom-0 md:left-1/2 md:-translate-x-1/2 translate-x-0 md:-bottom-1 left-0 w-full max-w-[30rem] md:max-w-none rounded-2xl md:rounded-[3rem] h-fit p-4 md:p-6 shadow-green bg-white border-2 border-[#00C50A] flex flex-col items-center justify-center'>
                            {!isMobile && (
                                <div className='w-full h-fit'>
                                    {selectedCourier !== "" && (
                                    <AllParcelSelector 
                                        distance={distance}
                                        selectedCourier={selectedCourier}
                                        docQuantity={docQuantity}
                                        setDocQuantity={setDocQuantity}
                                        setSelectedCourier={setSelectedCourier}
                                        setQuotId={setQuotId}
                                        setPageLoader={setPageLoader}
                                        selectedType={selectedType}
                                        setSelectedType={setSelectedType}
                                        parcelImage={parcelImage}
                                        setParcelImage={setParcelImage}
                                    />)}
                                </div>
                            )}

                            <div className='w-full h-fit flex gap-4'>
                                {courierTypes.map((item, id) => (
                                <CourierTypeButton
                                    key={id}
                                    item={item}
                                    selectedCourier={selectedCourier}
                                    quotData={quotData}
                                    isMobile={isMobile}
                                    handleCourierSelect={handleCourierSelect}
                                />))}
                            </div>
                            
                            {isMobile && (
                            <div className='w-full'>
                                {selectedCourier !== "" && (
                                    <AllParcelSelector 
                                        distance={distance}
                                        selectedCourier={selectedCourier}
                                        docQuantity={docQuantity}
                                        setDocQuantity={setDocQuantity}
                                        setSelectedCourier={setSelectedCourier}
                                        setQuotId={setQuotId}
                                        setPageLoader={setPageLoader}
                                        selectedType={selectedType}
                                        setSelectedType={setSelectedType}
                                        parcelImage={parcelImage}
                                        setParcelImage={setParcelImage}
                                    />
                                )}
                            </div>)}

                            {quotData && quotData.estimated_amount && (
                            <EstimatedAmount
                                estimatedAmount={Math.round(quotData.estimated_amount)}
                                setShowOfferPopUp={setShowOfferPopUp}
                            />)}

                            {!showOfferPopUp && offerPrice !== 0 && offerPrice && (
                            <OfferedFare 
                                offerPrice={offerPrice} 
                            />)}

                            {quotId && quotData && (
                            <>
                                <div className='w-full'>
                                    <div className='w-full border-[0.5px] my-3 border-[#42a462]'></div>
                                </div>
                                <div className='w-full max-w-[30rem]'>
                                    <GradientBtn
                                        text="Find a Driver"
                                        styles={{ fontWeight: "600", fontSize: "1.4rem" }}
                                        onClickHandler={submitOrder}
                                    />
                                </div>
                            </>)}
                        </div>
                    </div>
                </div>
            </MobileBackground>
        </APIProvider>
    );
}

// Helper Components
const CourierTypeButton = ({ item, selectedCourier, quotData, isMobile, handleCourierSelect }) => {
    const isSelected = selectedCourier === item.type || (quotData && item.type.toUpperCase() === quotData.parcel_type);
    const buttonStyle = isSelected
        ? isMobile
            ? { border: "2px solid #F95426" }
            : { background: "#28C928" }
        : {};
    const textStyle = isSelected && !isMobile ? { color: "#ffffff" } : {};

    return (
        <div 
            onClick={() => handleCourierSelect(item.type)}
            style={buttonStyle}
            className='w-1/3 bg-white aspect-square md:aspect-auto flex flex-col md:flex-row shadow-md items-center justify-center md:justify-start gap-2 rounded-xl md:rounded-3xl border-[1px] border-[#6f6f6f] md:border-[#00C50A] md:p-3'
        >
            <img src={item.icon} className='w-1/2 md:w-fit md:h-16' alt={item.type} />
            <p style={textStyle} className='capitalize text-xs font-medium text-opacity-40 md:text-opacity-100 md:text-[#00c50a] md:text-xl'>
                {item.type}
            </p>
        </div>
    );
};

const EstimatedAmount = ({ estimatedAmount, setShowOfferPopUp }) => (
    <div className='w-full text-lg flex items-end justify-between mt-3 px-4'>
        <p className='font-semibold'>Estimated Amount:</p>
        <div className='flex gap-2'>
            <p>₹ {estimatedAmount}</p>
            <button onClick={() => setShowOfferPopUp(true)} className='text-[#42a462] underline text-sm font-medium'>
                Bargain?
            </button>
        </div>
    </div>
);

const OfferedFare = ({ offerPrice }) => (
    <div className='w-full flex items-center justify-between px-4'>
        <p className='font-semibold'>Offered Fare:</p>
        <p>₹ {offerPrice}</p>
    </div>
);

export default SubTotal;