import { IoClose } from "react-icons/io5"
import axiosInstance from "../../../utils/axiosInstance"


const OfferYourFarePopUp = ({ userId, offerPrice, setOfferPrice, setPageLoader, setShowOfferPopUp, pickUpLocation, dropLocation }) => {

    const handleClose = () => {
        setOfferPrice(null)
        setShowOfferPopUp(false)
    }

    const handleSubmit = () => {
        if (pickUpLocation.address === "" || dropLocation.address === "") {
            alert("please enter pickup and drop location")
            setShowOfferPopUp(false)
            return
        }

        const postData = {
            type : "Parcel" ,
            pickup_location : pickUpLocation.address,
            drop_location : dropLocation.address,
            offer_fare : offerPrice
        }
        
        setPageLoader(true)
        axiosInstance
        .post(`/bargaining/${userId}/`, postData)
        .then(res => {
            console.log(res)
            setShowOfferPopUp(false)
            setPageLoader(false)
        })
        .catch(err => {
            console.error(err)
            setPageLoader(false)
        })
    }

    return (
        <div className='w-screen h-screen fixed top-0 left-0 z-50 flex items-center justify-center p-5 backdrop-blur-sm'>
            <div className='w-full relative max-w-[30rem] rounded-3xl bg-white p-6 shadow-green'>
                <button
                    onClick={handleClose}
                    className='absolute top-5 right-5 p-1 aspect-square flex items-center justify-cente rounded-full text-2xl'
                >
                    <IoClose />
                </button>
                <p className='text-center text-orange-600 font-semibold text-2xl'>
                    Offer Your Fare
                </p>
                <input
                    type='number'
                    className='w-full mt-6 mb-4 border-2 rounded-lg h-14 p-4 bg-white focus:outline-none'
                    value={offerPrice}
                    onChange={e => setOfferPrice(e.target.value)}
                    min={10}
                />
                <div className='w-full flex justify-end'>
                    <button
                        onClick={handleSubmit}
                        className='px-5 py-1 bg-green-700 rounded-2xl flex items-center justify-center text-white'
                    >
                            Submit
                    </button>
                </div>
            </div>
        </div>
    )
}

export default OfferYourFarePopUp;