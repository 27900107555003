import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from "react-router-dom"
import tookiLogo from "../../assets/logo/tookiLogo.png"
import loginImage from "../../assets/login/login.png"
import { ThreeDots } from 'react-loader-spinner';
import axiosInstance from '../../utils/axiosInstance';
import EnterOtp from './EnterOtp';
import { useUserContext } from '../../globalComponent/context/UserContext';
import "../../styles/component/login/Login.css";

const LoginOtp = ({ setOtpLogin}) => {
    const navigate = useNavigate()
    const location = useLocation()
    const numberFromSignUp = location.state?.phoneNumber
    const { setAlert } = useUserContext()
    const [ phoneNumber, setPhoneNumber ] = useState("")
    const [ disabled, setDisabled ] = useState(true)
    const [ loader, setLoader ] = useState(false)
    const [ otpSent, setOtpSent ] = useState(false)

    useEffect(() => {
        // Enable button only when the phone number has exactly 10 digits (ignoring hyphens)
        if (phoneNumber && phoneNumber.replace(/\D/g, '').length === 10) {
            setDisabled(false);
        } else {
            setDisabled(true);
        }
    }, [phoneNumber]);

    useEffect(() => {
        if(numberFromSignUp) {
            setPhoneNumber(numberFromSignUp)
        }
    }, [numberFromSignUp])

    const handleSendOtp = () => {
        setLoader(true)

        axiosInstance
        .post("/login", { phone_number: `${phoneNumber}`, login_type: "consumer"})
        .then(res => {
            console.log(res)
            setOtpSent(true)
        })
        .catch(err => {
            console.error(err)
            const res = err.response.data
            if (res.message === "User does not exist" || res.message === "Invalid Credentials") {
                setAlert("Please Sign Up")
                console.log(phoneNumber)
                navigate("/signup", { state: { phoneNumber: phoneNumber }})
            }
        })
        .finally(() => {
            setLoader(false)
        })
    }

  return (
    <div className='w-full h-screen py-12 px-6 lg:flex items-center justify-center lg:bg-orange-600'>
        <div className='relative lg:hidden flex items-center justify-center w-full'>
            <img
                src={tookiLogo}
                className='w-16 h-16 absolute top-1/2 -translate-y-1/2 left-0'
                alt='tooki'
            />
            <p className='text-center text-orange-600 supermercado text-3xl'>
                Sign In
            </p>
        </div>
        
        {otpSent && 
        <div className='lg:flex w-full relative h-full lg:bg-white lg:rounded-[3rem] max-w-[75rem] lg:max-h-[40rem] items-center justify-center'>
        <img
            src={tookiLogo}
            className='w-48 hidden lg:block aspect-square absolute top-0 -translate-y-1/2 left-1/2 -translate-x-1/2'
            alt='tooki'
        />      

        <EnterOtp 
            phoneNumber={phoneNumber} 
            setOtpSent={setOtpSent} 
        />

        <div className='h-4/5 bg-orange-600 w-[1.4px] rounded-full hidden lg:flex'></div>
            <div className="hidden lg:flex w-1/2 h-full items-center justify-center p-10">
                <img
                    src={loginImage}
                    className='w-full h-full object-cover'
                    alt='login'
                />
            </div>
        </div>
        }

        {!otpSent && 
        <div className='w-full relative h-full max-w-[75rem] max-h-[40rem] lg:flex items-center justify-center lg:rounded-[3rem] lg:bg-white'>
            <img
                src={tookiLogo}
                className='w-48 aspect-square hidden lg:block absolute top-0 -translate-y-1/2 left-1/2 -translate-x-1/2'
                alt='tooki'
            />

            <div className='w-full lg:w-1/2 pt-10 lg:pt-0 px-5 flex flex-col items-center lg:px-[10%] justify-start'>
                <p className='hidden lg:block text-center text-orange-600 supermercado text-3xl'>
                    Sign In
                </p>

                <div className='w-full lg:mt-6 items-center justify-center'>
                    <p className='text-2xl text-center text-slate-400 supermercado pb-4'>
                        Welcome
                    </p>
                </div>
                <div className='w-full lg:hidden aspect-square mb-5'>
                    <img
                        src={loginImage}
                        className='w-full h-full object-cover'
                        alt='login'
                    />
                </div>
                <p className='text-gray-500 text-opacity-50 text-left text-xs'>
                    Please enter the mobile number and wait for OTP
                </p>

                <div className='mt-5 text-left'>
                    <label className=' text-left text-orange-600'>
                        Phone Number:
                    </label>
                    <div className='flex mt-2 items-center justify-center h-10 gap-2'>
                        <div className='bg-orange-600 w-16 h-full flex items-center justify-center bg-opacity-20 rounded-xl border-2 border-orange-600 border-opacity-40'>
                            +91
                        </div>
                        <input  
                            type='number'
                            disabled={loader}
                            className='bg-orange-600 px-4 h-full focus:outline-none bg-opacity-20 rounded-xl border-2 border-orange-600 border-opacity-40'
                            placeholder='eg: 888-999-0000'
                            value={phoneNumber}
                            onChange={e => {
                                const value = e.target.value;
                                // Allow only numbers and limit to 10 digits
                                if (/^\d{0,10}$/.test(value)) {
                                    setPhoneNumber(value);
                                }
                            }}
                            maxLength={10}
                        />
                    </div>
                </div>
                <button 
                    disabled={(disabled || loader)? true : false} 
                    onClick={handleSendOtp}
                    className='bg-orange-600 disabled:bg-black max-w-[17.815rem] disabled:bg-opacity-20 mt-10 w-full flex items-center justify-center h-10 rounded-xl font-medium text-white'
                >
                    {loader? <ThreeDots color='#fff' /> : 'Send Otp'}
                </button>
                <button disabled={loader} onClick={() => setOtpLogin(false)} className='bg-transparent mt-4 w-full flex items-center justify-center rounded-xl font-medium text-orange-600'>
                    Sign In With Email
                </button>
            </div>
            <div className='h-4/5 bg-orange-600 w-[1.4px] rounded-full hidden lg:flex'></div>
            <div className="hidden lg:flex w-1/2 h-full items-center justify-center p-10">
                <img
                    src={loginImage}
                    className='w-full h-full object-cover'
                    alt='login'
                />
            </div>
        </div>}
    </div>
  )
}

export default LoginOtp