import React from 'react'
import MobileBackground from '../globalComponent/ui/MobileBackground'

const BookAgainPage = () => {
  const customerCare = "9511724041"

  const handleCopy = () => {
    navigator.clipboard.writeText(customerCare)
    alert("Customer care number copied")
  }

  return (
    // <Routes>
    //     <Route path='/' element={<BookSelection />} />
    // </Routes>
    <MobileBackground title={"Book Specific"}>
      <div className='w-full h-full relative md:bg-white md:rounded-[3rem] flex items-center justify-start flex-col px-20 pt-20'>
        <p className='text-2xl text-wrap font-semibold'>
          To Book a specific driver or vehicle you need to call us on this number{" "}
          <span 
            onClick={handleCopy}
            className='text-green-600 underline font-normal'
          >
            {customerCare}
          </span>
        </p>

        <div className='w-full absolute bottom-0 md:bottom-5 left-0 flex items-center justify-center gap-5 px-5'>
          <a 
            href={`tel:${customerCare}`}
            className='w-1/2 py-4 text-center bg-green-600 rounded-full text-2xl text-white font-bold'
          >
              Call
          </a>
          <button 
            onClick={handleCopy}
            className='w-1/2 py-4 text-center bg-black bg-opacity-30 rounded-full text-2xl text-gray-900 font-bold'
          >
              Copy
          </button>
        </div>
      </div>
    </MobileBackground>
  )
}

export default BookAgainPage