import React, { useEffect, useCallback } from 'react';
import { GoogleMap, Marker, Polyline } from '@react-google-maps/api'
import { Rings } from 'react-loader-spinner';
import { useLoader } from './LoaderContext';

import mapStyles from './mapStyles'
// import blueDot from "../../../assets/icons/blue-dot.svg"
import greenPin from "../../../assets/icons/green-pin.svg"
import redPin from "../../../assets/icons/red-pin.svg"

const GoogleMapBg = ({
    pickUpLocation,
    dropLocation,
    // currentLocation,
    directions,
    setDirections,
    setDistance,
    map,
    setMap
}) => {
    const { isLoaded } = useLoader();

    // Memoized function to calculate distance and set directions
    const calculateDistance = useCallback(() => {
        const origin = pickUpLocation.position;
        const destination = dropLocation.position;

        const directionsService = new window.google.maps.DirectionsService();
        directionsService.route(
            {
                origin,
                destination,
                travelMode: window.google.maps.TravelMode.DRIVING,
            },
            (result, status) => {
                if (status === window.google.maps.DirectionsStatus.OK) {
                    setDirections(result);
                    const distanceInMeters = result.routes[0].legs[0].distance.value;
                    setDistance(distanceInMeters / 1000); // Convert to kilometers

                    // Adjust map bounds to show the full route
                    const bounds = new window.google.maps.LatLngBounds();
                    bounds.extend(origin);
                    bounds.extend(destination);
                    map.fitBounds(bounds, {
                        top: 20,
                        left: 0,
                        bottom: 100,
                        right: 0,
                    });
                } else {
                    console.error(`Error fetching directions: ${status}`);
                }
            }
        );
    }, [pickUpLocation, dropLocation, map, setDirections, setDistance]);

    // Trigger distance calculation when conditions change
    useEffect(() => {
        if (pickUpLocation?.position?.lat && pickUpLocation?.position?.lng && 
            dropLocation?.position?.lat && dropLocation?.position?.lng && 
            isLoaded && map) {
            calculateDistance();
        }
    }, [pickUpLocation, dropLocation, isLoaded, calculateDistance, map]);

    // If Google Maps API is not loaded, show loading spinner
    if (!isLoaded) {
        return <Rings />;
    }

    return (
        <div className='w-full absolute top-0 left-0 h-full'>
            <GoogleMap
                zoom={13}
                onLoad={mapInstance => setMap(mapInstance)}
                mapContainerStyle={{ width: "100%", height: "100%" }}
                center={pickUpLocation.position}
                options={{
                    styles: mapStyles,
                    disableDefaultUI: true,
                }}
            >
                {directions && (
                    <Polyline
                        path={directions.routes[0].overview_path}
                        options={{
                            strokeColor: "#FFA500", // Orange color for pathline
                            strokeOpacity: 1,
                            strokeWeight: 3,
                        }}
                    />
                )}

                {pickUpLocation?.address !== "" && (
                    <Marker
                        position={pickUpLocation.position}
                        icon={{
                            url: greenPin,
                            scaledSize: new window.google.maps.Size(30, 30),
                            origin: new window.google.maps.Point(0, 0),
                            anchor: new window.google.maps.Point(15, 28)
                        }}
                        label={{ text: " ", color: "#000000", fontWeight: "bold" }}
                    />
                )}

                {dropLocation?.address !== "" && (
                    <Marker
                        position={dropLocation.position}
                        icon={{
                            url: redPin,
                            scaledSize: new window.google.maps.Size(30, 30),
                            origin: new window.google.maps.Point(0, 0),
                            anchor: new window.google.maps.Point(15, 28)
                        }}
                        label={{ text: " " }}
                    />
                )}
            </GoogleMap>
        </div>
    );
};

export default GoogleMapBg;