import React, { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom"
import Background from './component/Background'
import image1 from "../../assets/tutorial/delivery.png"
import image2 from "../../assets/tutorial/scrolling.png"
import image3 from "../../assets/tutorial/parcel.png"
import { useMobile } from "../../globalComponent/context/MobileContext"
import "../../styles/component/Tutorial/Tutorial.css"

const pageData = [
    {
        id: 0,
        image: image1,
        title: 'Trusted Parcel Point',
        para: 'TOOKI: Your on-demand driver for everything! Send parcels, documents, or hire vehicles in a snap. Fast, reliable, and at your fingertips—TOOKI makes transporting your essentials effortless. Just tap, track, and relax!',
        mobileText: 'TOOKI: Your on-demand driver for everything! Send parcels, documents, or hire vehicles in a snap. Fast, reliable, and at your fingertips—TOOKI makes transporting your essentials effortless. Just tap, track, and relax!'
    },
    {
        id: 1,
        image: image2,
        title: 'Find your best service',
        mobileText: 'Delivering excellence with every request. We pride ourselves on offering the best service and unmatched customer satisfaction. From parcels to vehicle hires, TOOKI ensures your items are handled with utmost care and delivered right on time. Experience the difference with Tooki!',
        para: 'Delivering excellence with every request. We pride ourselves on offering the best service and unmatched customer satisfaction. From parcels to vehicle hires, TOOKI ensures your items are handled with utmost care and delivered right on time. Experience the difference with Tooki!'
    },
    {
        id: 2,
        image: image3,
        title: 'Pickup and Drop system',
        mobileText: 'Need something delivered fast? TOOKI connects you with the nearest driver for quick pick-up and drop-off of your parcels. Anytime, anywhere, we ensure your items are on the move in no time!',
        para: 'Need something delivered fast? TOOKI connects you with the nearest driver for quick pick-up and drop-off of your parcels. Anytime, anywhere, we ensure your items are on the move in no time!'
    }
];

const Tutorial = () => {
    const { isMobile } = useMobile()
    const navigate = useNavigate()
    const [ currentPage, setCurrentPage ] = useState(0)
    const [ currentPageData, setCurrentPageData ] = useState(pageData[0])

    useEffect(() => {
        setCurrentPageData(pageData[currentPage])
    }, [currentPage])

    const handlePageChange = (direction) => {
        if (direction === "prev" && currentPage !== 0) {
            setCurrentPage(prev => prev - 1)
        } else if (direction === "next" && currentPage !== pageData.length - 1) {
            setCurrentPage(prev => prev + 1)
        } else if (direction === "next" && currentPage === pageData.length - 1) {
            navigate("/login")
        }
    }

  return (
    <Background noBackBtn={true} image={currentPageData.image}>
        <div className="tutorial-main-container-div">
        <p className='tutorial-main-title supermercado'>
            {currentPageData.title}
        </p>
        <p className='tutorial-main-paragraph md:pt-10 md:px-10'>
            {isMobile? (currentPageData.mobileText) : (currentPageData.para)}
        </p>

        <div className='tutorial-nav-div px-5'>
            <button onClick={() => handlePageChange("prev")} className='nav-btn'>
                Prev
            </button>

            <div className='tutorial-pagination'>
                {pageData.map((_, index) => 
                <div 
                    key={index}
                    onClick={() => setCurrentPage(index)}
                    className={(index === currentPage)? "page-dot active-dot" : "page-dot"}
                ></div>)}
            </div>

            <button onClick={() => handlePageChange("next")} className='nav-btn'>
                Next
            </button>
        </div>
        </div>
    </Background>
  )
}

export default Tutorial