import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { IoPinOutline } from "react-icons/io5";
import { ThreeDots } from "react-loader-spinner";
import { StandaloneSearchBox } from '@react-google-maps/api'

const LocationSelections = ({ 
  isLoaded,
  pickUpLocation, 
  setPickUpLocation,
  dropLocation,
  setDropLocation,
  currentLocation,
  setCurrentLocation
 }) => {
  const pickupRef = useRef(null)
  const dropRef = useRef(null)
  const [ loader, setLoader] = useState(false);

  const getAddress = async (lat, lng) => {
    setLoader(true);
    try {
      const response = await axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${process.env.REACT_APP_GOOGLE_API_KEY}`
      );
      const address = response.data.results[0].formatted_address;
      setCurrentLocation((prevState) => ({
        ...prevState,
        address: address
      }));
      setLoader(false);
    } catch (error) {
      console.error("Error fetching location", error);
      setLoader(false);
    }
  };

  useEffect(() => {
    setLoader(true);
    console.log("getting coordinates");
    navigator.geolocation.getCurrentPosition(
      (position) => {
        getAddress(position.coords.latitude, position.coords.longitude);
        setCurrentLocation(prev => ({
          ...prev,
          position: {
            lat: position.coords.latitude,
            lng: position.coords.longitude
          }
        }))
        console.log(position.coords.latitude, position.coords.longitude);
        setLoader(false);
      },
      (error) => {
        if (error.code === error.PERMISSION_DENIED) {
          setLoader(false);
          alert(
            "Location services are disabled. Please enable location services to move forward."
          );
        } else {
          console.error(error);
          setLoader(false);
        }
      }
    );
  }, []);

  const handleOnPlacesChanged = (inputRef, setLocation) => {
    let address = inputRef.current.getPlaces()
    const lat = address[0].geometry.location.lat()
    const places = inputRef.current.getPlaces();
    if (places.length > 0) {
      const place = places[0];
      const lat = place.geometry.location.lat();
      const lng = place.geometry.location.lng();
      
      // Ensure the location is in India before setting it
      if (place.formatted_address.includes('India')) {
        setLocation({
          address: place.formatted_address,
          position: { lat, lng }
        });
      } else {
        alert('Please select a location within India.');
      }
    }
  }

  const searchOptions = {
    componentRestrictions: { country: "IN" }
  };

  return (
    <div className="w-full h-fit">
      <div className="w-full pl-2 shadow-md mb-2 h-1/2 flex items-center justify-between gap-3 bg-white rounded-md">
        <p className="text-green-600 min-w-16 w-[25%] h-10 font-semibold flex items-center justify-start">
          <IoPinOutline /> Pickup
        </p>
        <div className="relative w-full rounded-lg">
          {isLoaded && 
          <StandaloneSearchBox
            options={searchOptions}
            onLoad={(ref) => pickupRef.current = ref}  
            onPlacesChanged={() => handleOnPlacesChanged(pickupRef, setPickUpLocation)}
          >
            <input
              type='text'
              placeholder={`Pickup Location${loader? ("     ") : ''}`}
              className="w-full focus:outline-none pr-1"
              value={pickUpLocation.address}
              onChange={e => setPickUpLocation(prev => ({ ...prev, address: e.target.value}))}
            />
          </StandaloneSearchBox>}
          {loader && pickUpLocation.address !== "" &&
            <div className="absolute right-4 top-1/2 transform -translate-y-1/2">
                <ThreeDots
                    visible={true}
                    height="20"
                    width="20"
                    color="#787878"
                    radius="9"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                />
            </div>}
        </div>
      </div>
      <div className="w-full pl-2 shadow-md mb-2 h-1/2 flex items-center justify-between gap-3 bg-white rounded-md">
        <p className="text-red-600 min-w-16 w-[25%] h-10 font-semibold flex items-center justify-start">
          <IoPinOutline /> Drop
        </p>
        <div className="w-full rounded-lg">
          {isLoaded &&
          <StandaloneSearchBox 
            options={searchOptions}
            onLoad={(ref) => dropRef.current = ref}
            onPlacesChanged={() => handleOnPlacesChanged(dropRef, setDropLocation)}
          >
            <input
              type="text"
              placeholder="Drop Location"
              className="w-full focus:outline-none pr-1"
              value={dropLocation.address}
              onChange={(e) =>
                setDropLocation((prev) => ({ ...prev, address: e.target.value }))
              }
            />
          </StandaloneSearchBox>}
        </div>
      </div>
    </div>
  );
};

export default LocationSelections;
