import React from 'react'
import Review from '../components/reviews/Review'

const ReviewPage = () => {
  return (
    <>
        <Review />
    </>
  )
}

export default ReviewPage