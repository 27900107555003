import React, { useState } from 'react'
import { useNavigate } from "react-router-dom"
import { useForm } from 'react-hook-form';
import { IoIosMail, IoIosLock, IoMdEye, IoMdEyeOff } from "react-icons/io";
// import deliveryImage from "../../assets/login/delivery.png"
import loginImage from "../../assets/login/login.png"
import Background from '../Tutorial/component/Background'
import { ThreeDots } from 'react-loader-spinner';
import "../../styles/component/login/Login.css"

const Loader = () => {
    return (
        <ThreeDots
            visible={true}
            height="80"
            width="80"
            color="#fff"
            radius="9"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClass=""
        />
    )
}

const Login = ({ goToSignUp, onSubmit, loader, errorMessage, setForgotPass, setOtpLogin }) => {
    const { register, handleSubmit, formState: { errors } } = useForm()
    const [showPassword, setShowPassword] = useState(false)

  return (
    <Background noBackBtn={true} image={loginImage}>
       <div className='login-main-div relative'>

            {/* {errorMessage &&
            <p className='pb-3 text-red-500 font-light glow-red absolute top-3 left-1/2 -translate-x-1/2'>
                {errorMessage}
            </p>} */}

            <p className='main-title supermercado'>
                Sign In
            </p>
            <p className='welcome-text supermercado'>
                Welcome!
            </p>

            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="form-group">
                    <div className='icon-wrap'>     
                        <IoIosMail />
                    </div>
                    <input
                        type="email"
                        placeholder='Email'
                        {...register("email", { required: true })}
                        className={errors.email? 'form-control input-error' : "form-control"}
                    />
                </div>

                <div className="form-group">
                    <div className='icon-wrap'>
                        <IoIosLock />
                    </div>
                    <input
                        type={showPassword ? 'text' : 'password'}
                        placeholder='Password'
                        {...register("password", { required: true })}
                        className={errors.email? 'form-control input-error' : "form-control"}
                    />
                    <button
                        type="button"
                        className="show-hide-btn"
                        onClick={() => setShowPassword(!showPassword)}
                    >
                        {showPassword ? <IoMdEyeOff /> : <IoMdEye /> }
                    </button>
                </div>

                <div className='w-full flex items-center justify-between mb-2'>
                    <div className='checkbox-div'>
                        <p onClick={() => setOtpLogin(true)} className='underline text-orange-600 text-xs font-medium'>
                            sign in with otp
                        </p>
                    </div>
                    <button 
                        onClick={() => setForgotPass(true)}
                        className='text-sm text-gray-600 text-opacity-70'
                    >
                        Forgot Password?
                    </button>
                </div>

                <button type="submit" className="main-login-btn">
                    {loader? <Loader /> : "Login"}
                </button>
            </form>

            <button onClick={goToSignUp} className='create-new-btn'>
                Create a new account
            </button>
       </div>
    </Background>
  )
}

export default Login